var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('CRow',[_c('CCol',{attrs:{"md":"9"}},[_c('DataTable',{attrs:{"items":_vm.data.moneyTransactions.items,"fields":['amount', 'transactionDate', 'source', 'ownerDisplayName'],"table-filter-value":_vm.tableFilterValue,"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"source",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.$t('enums.moneyTransactionSourceType.' + item.source)))])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ appendToBody: true, content: item.id }),expression:"{ appendToBody: true, content: item.id }"}]},[_vm._v(_vm._s(item.name))])])]}},{key:"transactionDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.transactionDate}})],1)]}}],null,false,861903762)}),_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CreateCard',{attrs:{"loading":_vm.loading,"errorMessage":_vm.message,"title":"Neue Zahlung"},on:{"handleSubmit":_vm.handleSubmit}},[_c('ValidatedInput',{attrs:{"label":"Betrag","field":_vm.$v.newMoneyTransaction.amount,"value":_vm.$v.newMoneyTransaction.amount.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.newMoneyTransaction.amount, "$model", $event)}}}),_c('ValidatedInput',{attrs:{"label":"Datum","field":_vm.$v.newMoneyTransaction.transactionDate,"type":"date","value":_vm.$v.newMoneyTransaction.transactionDate.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.newMoneyTransaction.transactionDate, "$model", $event)}}}),_c('ValidatedSelect',{attrs:{"label":"Quelle","placeholder":"Bitte wählen","options":_vm.moneyTransactionSourceTypeOptions,"field":_vm.$v.newMoneyTransaction.source,"value":_vm.$v.newMoneyTransaction.source.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.newMoneyTransaction.source, "$model", $event)}}}),_c('ValidatedSelect',{attrs:{"label":"Vermieter","placeholder":"Bitte wählen","options":_vm.allOwnersOptions,"field":_vm.$v.newMoneyTransaction.ownerId,"value":_vm.$v.newMoneyTransaction.ownerId.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.newMoneyTransaction.ownerId, "$model", $event)}}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }