import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/MoneyTransaction/';

class AdminMoneyTransactionService {
  createNew(newMoneyTransaction: {}) {
    return ApiService.post(`${API_URL}Create`, newMoneyTransaction);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new AdminMoneyTransactionService();
