





























































import Vue from 'vue';
import AdminMoneyTransactionService from '@/services/admin/AdminMoneyTransactionService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import CreateCard from '@/components/forms/CreateCard.vue';
import ToastMessage from '@/models/ToastMessage';
import { MetaInfo } from 'vue-meta';

const validations = {
  newMoneyTransaction: {
    amount: {
      required,
    },
    source: {
      required,
    },
    ownerId: {
      required,
    },
    transactionDate: {
      required,
    },
  },
};

class TransactionDto {
  transactionDate!: Date;
}

class NewMoneyTransaction {
  amount = 0.0;
  transactionDate = Date.now();
  source = '';
  ownerId = '';
}

export default Vue.extend({
  mixins: [validationMixin],
  validations,
  components: {
    ValidatedInput,
    FormatedTimestamp,
    DataTable,
    CreateCard,
    ValidatedSelect,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminMoneyTransactionIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      data: {} as { allOwners: { displayName: string; id: string }[]; moneyTransactions: { items: TransactionDto[] } },
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'transactionDate', asc: false },
      currentPage: 1,
      loading: false,
      newMoneyTransaction: new NewMoneyTransaction(),
      ready: false,
      message: '',
      moneyTransactionSourceTypes: [],
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  computed: {
    moneyTransactionSourceTypeOptions(): { value: string; label: string }[] {
      return this.moneyTransactionSourceTypes.map((moneyTransactionSourceType) => {
        return {
          value: moneyTransactionSourceType,
          label: this.$t('enums.moneyTransactionSourceType.' + moneyTransactionSourceType).toString(),
        };
      });
    },
    allOwnersOptions(): { value: string; label: string }[] {
      return this.data.allOwners.map((owner) => {
        return { value: owner.id, label: owner.displayName };
      });
    },
  },
  methods: {
    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },
    loadData() {
      this.loading = true;
      AdminMoneyTransactionService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.ready = true;
        this.data = res.value;
        this.data.moneyTransactions.items = res.value.moneyTransactions.items.map((t: TransactionDto) => ({
          ...t,
          transactionDate: new Date(t.transactionDate),
        }));
        this.pages = res.value.moneyTransactions.totalPages;
        this.moneyTransactionSourceTypes = res.value.moneyTransactionSourceTypes;
      });
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        return AdminMoneyTransactionService.createNew(this.newMoneyTransaction).then(
          () => {
            this.$store.commit('addToastMessage', new ToastMessage('Zahlung erfolgreich angelegt', 'bg-success'));
            this.loadData();
            this.message = '';
          },
          (error) => {
            this.$store.commit('addToastMessage', new ToastMessage('Anlegen fehlgeschlagen', 'bg-warning'));
            this.loading = false;
            this.message = error;
          }
        );
      }
    },
    setBreadcrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminMoneyTransactionIndex.breadcrumb.last' },
      ]);
    },
  },
  mounted() {
    this.loadData();
    this.setBreadcrumb();
  },
});
